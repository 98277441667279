/* @font-face {
  font-family: "GilroyRegular";
  font-display: swap;
  src: url("./components/assets/Fonts/Gilroy/Gilroy-Regular.ttf")
    format("truetype");
} */

@font-face {
  font-family: "Averta";
  font-display: swap;
  src: url("./components/assets/Fonts/Averta/Averta-100.otf");
  font-weight: 100;
}
@font-face {
  font-family: "Averta";
  font-display: swap;
  src: url("./components/assets/Fonts/Averta/Averta-200.otf");
  font-weight: 200;
}
@font-face {
  font-family: "Averta";
  font-display: swap;
  src: url("./components/assets/Fonts/Averta/Averta-300.otf");
  font-weight: 300;
}
@font-face {
  font-family: "Averta";
  font-display: swap;
  src: url("./components/assets/Fonts/Averta/Averta-400.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Averta";
  font-display: swap;
  src: url("./components/assets/Fonts/Averta/Averta-500.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Averta";
  font-display: swap;
  src: url("./components/assets/Fonts/Averta/Averta-600.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Averta";
  font-display: swap;
  src: url("./components/assets/Fonts/Averta/Averta-700.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Averta";
  font-display: swap;
  src: url("./components/assets/Fonts/Averta/Averta-800.otf");
  font-weight: 800;
}

b {
  font-weight: 500;
}

body,
html {
  height: 100%;

  font-family: "Averta", "Sans-Serif";
  font-display: swap;
  --bs-gutter-x: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
}

.wmde-markdown {
  font-family: "Averta", "Sans-Serif";
  font-display: swap;
}

.react-multiple-carousel__arrow {
  z-index: 1 !important;
}

.content-center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.text-container {
  padding: 2em 2em;
}

.margin-top {
  margin-top: 2em;
}

.h1-text {
  font-size: 4.5rem;
}

body::-webkit-scrollbar {
  display: none;
}

.h2-text {
  font-size: 3rem;
}

.h3-text {
  font-size: 2rem;
}

.h4-text {
  font-size: 1.5rem;
}

.h5-text {
  font-size: 1.333rem;
}

.h6-text {
  font-size: 1.166rem;
}

.p-text {
  font-size: 16px;
  margin-bottom: 0rem !important  ;

  /* white-space: pre-line; */
}

@media (max-width: 600px) {
  .h1-text {
    font-size: 2rem;
  }

  .h2-text {
    font-size: 1.5rem;
  }

  .h3-text {
    font-size: 1.5rem;
  }

  .h4-text {
    font-size: 1.3rem;
  }

  .h5-text {
    font-size: 1rem;
  }

  .h6-text {
    font-size: 0.8rem;
  }

  .p-text {
    font-size: 14px;
    margin-bottom: none !important  ;
    /* white-space: pre-line; */
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

/* Nav-Pills */

.nav.nav-pills .nav-link.active {
  background-color: #63b863;
}

.carousel-inner {
  border-radius: 5px !important;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  transition: 0.3s !important;
}

/* Primary overwrite */
.btn-primary {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #0a4525 !important;
  font-size: 16px !important;
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
  position: relative !important;
}

.dropdown-item:active {
  background-color: #0a4525 !important;
}

.dropdown-item::after {
  background-color: #0a4525 !important;
}

.btn-primary:hover {
  color: #158547 !important;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}

/* wobble Animation */

.wobble {
  animation: wobble 1s 1;
}

@keyframes wobble {
  25% {
    transform: rotate(2deg);
    transform: scale(0.8);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Progress Bar */
.progress-bar bg-success {
  background-color: #63b863 !important;
}

/* Accordion */
.accordion-button:not(.collapsed) {
  background-color: white !important;
  color: #0a4525 !important;
  border-color: white !important;
  border-radius: 15px;
}

.accordion-button:focus {
  border-color: white !important;
  border-radius: 15px !important;
}

/* Forms */

.form-control {
  background-color: #fefefe !important;
  border: 1px solid #d2d6dc !important;
  font-size: 100% !important;
  padding: 11px 16px !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(104 184 104 / 25%) !important;
}

.form-select {
  background-color: #fefefe !important;
  border: 1px solid #d2d6dc !important;
  color: #212529 !important;

  font-size: 100% !important;
  padding: 11px 16px !important;
}

.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgb(104 184 104 / 25%) !important;
}

.form-label {
  font-weight: 500;
  line-height: 1.8;
  color: #27374d;
  display: block;
  font-size: 14px;
}

.form-check-input:checked {
  background-color: #63b863 !important;
  border-color: #63b863 !important;
}

/* Modals */
.modal-dialog {
  margin: 4.75rem auto !important;
}
.modal-fullscreen {
  width: 100%;
}
body.modal-open {
  overflow-y: hidden;
  width: 100vw;
}

/* Mapbox */
.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact:after {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

/* react-typeout */

.react-typeout {
  color: #63b863;
  display: inline;
}

:root {
  --primary-green-700: #163116;
  --primary-green-600: #2c612c;
  --primary-green-500: #429242;
  --primary-green-400: #63b863;
  --primary-green-300: #8aca8a;
  --primary-green-200: #b1dbb1;
  --primary-green-100: #e8f5e8;

  --primary-dark-green-700: #031109;
  --primary-dark-green-600: #052313;
  --primary-dark-green-500: #08341c;
  --primary-dark-green-400: #0a4525;
  --primary-dark-green-300: #18a357;
  --primary-dark-green-200: #43e48c;
  --primary-dark-green-100: #a1f1c6;

  --primary-purple: #632340;
  --primary-pink: #ffd6e4;

  --primary-red-700: #311616;
  --primary-red-600: #612c2c;
  --primary-red-500: #924242;
  --primary-red-400: #b86363;
  --primary-red-300: #ca8a8a;
  --primary-red-200: #dbb1b1;
  --primary-red-100: #edd8d8;

  --primary-blue-700: #162431;
  --primary-blue-600: #2c4761;
  --primary-blue-500: #426b92;
  --primary-blue-400: #638eb8;
  --primary-blue-300: #8aaaca;
  --primary-blue-200: #b1c6db;
  --primary-blue-100: #d8e3ed;

  --primary-bg: #f5f5f5;
  --primary-bg-light: #fafafa;
  --primary-bg-dark: #d6d6d6;
  --secondary-gray: #e8edf2;
  --secondary-gray-darker: #d5dee7;

  --primary-border: #e4e5e7;
  --primary-border-light: rgb(242 244 249);
  --box-shadow-1: rgba(47, 48, 97, 0.12) 0px 6px 12px;

  --text-main: #222325;
  --text-body: #62646a;
  --text-mute: #95979d;

  --space-mid: 1rem;
  --max-width: 1140px;
}
