.alert {
  display: flex;
  align-items: center;
  position: fixed;
  height: fit-content;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 8px;

  padding: 10px;
  z-index: 2;

  background-color: #69b869 !important;
  width: fit-content;
  height: fit-content;
}
.fixed {
  position: fixed;
  bottom: 0;

  width: 100%;
}
.alertDanger {
  display: flex;
  align-items: center;
  position: fixed;
  height: fit-content;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 8px;

  padding: 10px;
  z-index: 100000;

  background-color: #fdae61;
  width: fit-content;
  height: fit-content;
}

.msg {
  color: white;
  font-size: 18px;
  margin-right: 1rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0rem !important;
}

.progressBar {
  background-color: #ff8913;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.progress {
  border-bottom-left-radius: 8px;
  z-index: 100;
  width: 100%;
  border-bottom-right-radius: 8px;
  height: 8px;
  background: #ffd4aa;
  animation: roundtime 7s 1;
  transform-origin: left center;
}

.progressBarSuccess {
  background-color: #2e5f2e;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.progressSuccess {
  border-bottom-left-radius: 8px;
  z-index: 100;
  width: 100%;
  border-bottom-right-radius: 8px;
  height: 8px;
  background: #ace5ac;
  animation: roundtime 7s 1;
  transform-origin: left center;
}

@keyframes roundtime {
  to {
    /* More performant than animating `width` */
    transform: scaleX(0);
  }
}

.icon {
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
}
.icon:hover {
  cursor: pointer;
}

.alertNav {
  margin: auto;
  margin-bottom: 20px;
  width: 60%;
}

@media (max-width:600px) {

  .alert {

    bottom: 120px;
   
  }

  .alertDanger{
    bottom: 120px;
  }

  .msg{
    font-size: 14px;
  }
  
}
