.navDiv {
  width: 100%;
  height: 7vh;
  background-color: #f7f8fa;
  padding: 0.5em;
  display: flex;
  align-items: center;
}

.rightSide {
  width: 7%;
  display: inline-block;
  text-align: center;
}

.navLogoDiv {
  width: 93%;
  display: inline-block;
  line-height: 1em;

  color: #094424;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.logoImg {
  max-width: 7em;
  margin-left: 1em;
  margin-right: 0.2em;
}

.userImage {
  max-width: 2.3em;
  max-height: 2.3em;
  clip-path: circle();
  margin: auto;
}
